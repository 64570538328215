import { Component } from '@angular/core';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { CellPreparedEvent, EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/date_range_box';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-clock-validation',
  templateUrl: './clock-validation.component.html',
  styleUrls: ['./clock-validation.component.scss']
})
export class ClockValidationComponent {
  
  now: Date;
  startDate: Date;
  endDate: Date;
  minDate: Date; 
  maxDate: Date;

  faseVisible = true;

  backendURL: string = environment.ApiUrl + '/api';
  remoteDataSource: CustomStore;
  constructionSitesStore: CustomStore;

  lookupErrorData = [
    { id: 1, name: 'Ok' },
    { id: 2, name: 'Avviso' },
    { id: 3, name: 'Errore' },
  ];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private restApi: RestApiService,
    private toolbar: ToolbarService) {

    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
    this.startDate = this.setToMonday(this.now);
    this.endDate = this.addDays(this.startDate, 7);
    this.minDate = this.addDays(this.startDate, - 120);
    this.maxDate = this.endDate;

    this.remoteDataSource = this.createDataSource();

    this.constructionSitesStore = this.restApi.getConstructionSitesStore();

  }

  createDataSource(): CustomStore {
    return createStore({
      key: 'clockRegistrationId',
      loadUrl: this.backendURL + '/ClockElaborations/Get?TenantId=' + this.restApi.readTenantId() + '&StartDate=' + this.startDate.toISOString() + '&EndDate=' + this.endDate.toISOString(),
      updateUrl: this.backendURL + '/ClockElaborations/Put',
      deleteUrl: this.backendURL + '/ClockElaborations/Delete',
    });
  }

  ngOnInit() {
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(null);

    this.toolbar.TitleChanged("Validazione Timbrature");

    this.restApi.getOptions().pipe(takeUntil(this.unsubscribe)).subscribe(o => this.faseVisible = (o?.workingPhase ?? 0) >= 1);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onValueChanged(e: ValueChangedEvent) {
    this.startDate = e.value[0];
    this.endDate = e.value[1];

    this.remoteDataSource = this.createDataSource();
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField === "workedHours" && e.parentType === "dataRow") {
      e.editorOptions.onFocusIn = (function (args: { element: { querySelector: (arg0: string) => { (): any; new(): any; select: { (): void; new(): any; }; }; }; }): void {
        console.log(args.element);
        args.element.querySelector(".dx-texteditor-input").select();
      })
    }
    if (e.dataField == "constructionSiteCode") {
      e.editorName = "dxLookup";  // "dxSelectBox" or "dxDropDownBox"  
      e.editorOptions.searchEnabled = true;
      e.editorOptions.searchExpr = "constructionSiteDescription"
      e.editorOptions.searchMode = "contains"
      e.editorOptions.showClearButton = true;
      e.editorOptions.usePopover = false;
      e.editorOptions.wrapItemText = true;
      e.editorOptions.placeholder = "Selezionare il Cantiere"
      e.editorOptions.dropDownOptions = {
        fullScreen: true,
        showTitle: false
      }
    }
    if (e.dataField == "workingPhaseCode") {
      const cantiere = e.row?.data.constructionSiteCode;
      const phaseDataSource = this.restApi.getConstructionPhasesDataSorce(cantiere);

      e.editorName = "dxLookup";
      e.editorOptions.searchEnabled = true;
      e.editorOptions.searchExpr = "constructionSiteDescription"
      e.editorOptions.searchMode = "contains"
      e.editorOptions.showClearButton = true;
      e.editorOptions.usePopover = false;
      e.editorOptions.wrapItemText = true;
      e.editorOptions.placeholder = "Selezionare il Cantiere"
      e.editorOptions.dataSource = phaseDataSource;
      e.editorOptions.dropDownOptions = {
        fullScreen: true,
        showTitle: false
      }
    }
  }

  onCellPrepared(e: CellPreparedEvent) {
    if (e.rowType === "data" && e.column.dataField === "errorLevel") {
      //e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      // Tracks the `errorLevel` data field
      if (e.watch) {
        e.watch(function () {
          return e.data.errorLevel;
        }, function () {
          //e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
        })
      }
    }
  }

  
  setToMonday(date: Date): Date {
    const monday = new Date(date.getTime());

    const day = monday.getDay() || 7;
    if (day !== 1)
      monday.setHours(-24 * (day - 1));
    return monday;
}
  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  sinistraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, -7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };

  destraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, 7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };
}

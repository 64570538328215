import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';

import { Constructionsite } from '../models/constructionsite'
import { ConstructionPhase } from '../models/construction-phase';
import { Cost } from '../models/cost'
import { HoursOption } from '../models/hours-option'
import { KindOfWork } from '../models/kind-of-work'
import { Tool } from '../models/tool'
import { WorkerCost } from '../models/worker-cost'
import { Worker } from '../models/worker'
import { Message } from '../models/message';
import { Registration } from '../models/registration';
import { environment } from '../../../environments/environment';

import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Clock } from '../models/clock';
import { ClockMarker } from '../models/clock-marker';
import { ClockRegistrationElaborationDetail } from '../models/clock-registration-elaboration';
import { CustomStore } from 'devextreme-aspnet-data-nojquery';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  private apiUrl;
  private tenantID: number;

  constructor(private http: HttpClient)
  {
    
    this.apiUrl = environment.ApiUrl + '/';
    this.tenantID = this.readTenantId();
  }

  readTenantId(): number {
    let tenant = localStorage.getItem("tenantId");
    tenant ??= "0";
    return +tenant;
  }

  getOptions(): Observable<HoursOption> {
    this.tenantID = this.readTenantId();
    return this.http.get<HoursOption>(this.apiUrl + 'Options/Tenant/' + this.tenantID);
  }

  getConstructionSites(): Observable<Constructionsite[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<Constructionsite[]>(this.apiUrl + 'ConstructionSites?tenantId=' + this.tenantID);
  }

  getConstructionPhases(): Observable<ConstructionPhase[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<ConstructionPhase[]>(this.apiUrl + 'ConstructionSites/ConstructionPhases?tenantId=' + this.tenantID);
  }

  getNearestContructionSiteCode(latitude: number, longitude: number): Observable<string>{
    this.tenantID = this.readTenantId();
    return this.http.get<string>(this.apiUrl + 'ConstructionSites/Nearest?tenantId=' + this.tenantID + '&latitudine=' + latitude + '&longitudine=' + longitude);
  }

  getConstructionSitesDataSorce(): DataSource {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    const token = localStorage.getItem("jwt");

    return new DataSource({
      store: AspNetData.createStore({
        key: 'constructionSiteCode',
        loadUrl: this.apiUrl + 'ConstructionSites/Lookup?tenantId=' + tenantId,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: "Bearer " + token
          }
        }
      }),
      key: 'constructionSiteCode',      
      paginate: true,
      pageSize: 10
    });
  }

  getConstructionSitesStore(): CustomStore {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    const token = localStorage.getItem("jwt");

    return AspNetData.createStore({
        key: 'constructionSiteCode',
        loadUrl: this.apiUrl + 'ConstructionSites/Lookup?tenantId=' + tenantId,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: "Bearer " + token
          }
        }
      });
  }

  getConstructionPhasesDataSorce(cantierePadre?: string): DataSource {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    let filtroPadre = ""
    let action = "PhaseLookup";

    if (cantierePadre) {
      filtroPadre = "&cantierePadre=" + cantierePadre;
      action = "GetPhaseFiltered";
    }

    const token = localStorage.getItem("jwt");

    return new DataSource({
      store: AspNetData.createStore({
        key: 'constructionSiteCode',
        loadUrl: this.apiUrl + 'ConstructionSites/' + action + '?tenantId=' + tenantId + filtroPadre,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: "Bearer " + token
          }
        }
      }),
      paginate: true,
      pageSize: 10,
    });
  }

  getCosts(): Observable<Cost[]> {
    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);

    return this.http.get<Cost[]>(this.apiUrl + 'costs?tenantId=' + this.tenantID + '&workerNumber=' + workerNumber);
  }

  getWorkerCosts(): Observable<WorkerCost[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<WorkerCost[]>(this.apiUrl + 'workers_costs?tenantId=' + this.tenantID);
  }

  getMessages(lastMessageId: number): Observable<Message[]> {
    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);
    return this.http.get<Message[]>(this.apiUrl + 'messages?tenantId=' + this.tenantID + '&lastReceivedMessage=' + lastMessageId + '&workerNumber=' + workerNumber);
  }

  markReadedMessages(message: Message): Observable<unknown> {
    return this.http.put(this.apiUrl + 'messages/readed/' + message.messageId, message);
  }

  getTools(): Observable<Tool[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<Tool[]>(this.apiUrl + 'tools?tenantId=' + this.tenantID);
  }

  getKindOfWorks(): Observable<KindOfWork[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<KindOfWork[]>(this.apiUrl + 'KindOfWorks?tenantId=' + this.tenantID);
  }

  getWorkers(): Observable<Worker[]> {
    this.tenantID = this.readTenantId();
    return this.http.get<Worker[]>(this.apiUrl + 'workers?tenantId=' + this.tenantID);
  }

  getCurrentWorker(): Observable<Worker> {
    this.tenantID = this.readTenantId();

    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3).trim();

    return this.http.get<Worker>(this.apiUrl + 'workers/' + this.tenantID + '/' + workerNumber);
  }

  getWorkersDataSorce(): DataSource {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";
    
    const token = localStorage.getItem("jwt");

    return new DataSource({
      store: AspNetData.createStore({
        key: 'workerNumber',
        loadUrl: this.apiUrl + 'Workers/Lookup?tenantId=' + tenantId,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: "Bearer " + token
          }
        }
      }),
      paginate: true,
      pageSize: 10,
    });
  }

  getRegistrations(inizio: string, fine: string): Observable<Registration[]> {
    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);

    return this.http.get<Registration[]>(this.apiUrl + 'registrations?tenantId=' + this.tenantID + '&registrationUser=' + workerNumber + '&startDate=' + inizio + '&endDate=' + fine);
  }

  postRegistration(reg: Registration): Observable<unknown> {
    return this.http.post(this.apiUrl + 'registrations', reg);
  }

  getClockDataSorce(): DataSource {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);
    const today = new Date();
    const minDate = new Date(today.getTime() - (1000 * 60 * 60 * 24 * 2));

    const token = localStorage.getItem("jwt");

    return new DataSource({
      store: AspNetData.createStore({
        key: 'clockId',
        loadUrl: this.apiUrl + 'clocks?tenantId=' + tenantId,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.headers = {
              Authorization: "Bearer " + token
          }
        }
      }),
      sort: { selector: 'deviceDate', desc: true },
      paginate: true,
      pageSize: 10,
      filter: [['workerNumber', '=', workerNumber], 'and', ['deviceDate', '>', minDate]],

    });
  }

  getClockAdminDataSorce(): DataSource {
    let tenantId = localStorage.getItem("tenantId");
    tenantId ??= "0";

    const token = localStorage.getItem("jwt");

    return new DataSource({
      store: AspNetData.createStore({
        key: 'clockId',
        loadUrl: this.apiUrl + 'clocks?tenantId=' + tenantId,
        deleteUrl: this.apiUrl + 'clocks/Delete',
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: "Bearer " + token
          }
        }
      }),
      sort: 'deviceDate',
      paginate: true,
      pageSize: 50,

    });
  }

  getClock(clockId: number): Observable<Clock> {
    return this.http.get<Clock>(this.apiUrl + 'clocks/'+ clockId);
  }

  putClock(clk: Clock): Observable<unknown> {
    return this.http.put(this.apiUrl + 'clocks/' + clk.clockId, clk);
  }

  postClock(clk: Clock): Observable<unknown> {
    return this.http.post(this.apiUrl + 'clocks', clk);
  }

  deleteClock(clockId: number): Observable<unknown> {
    return this.http.delete(this.apiUrl + 'clocks?key=' + clockId);
  }

  getLastClockConstructionSiteCode(): Observable<string> {
    this.tenantID = this.readTenantId();

    let user = localStorage.getItem("user");
    user ??= "   ";
    const workerNumber = user.substring(3);

    return this.http.get<string>(this.apiUrl + 'Clocks/LastClockConstructionSiteCode/' + this.tenantID + '/' + workerNumber );
  }

  getClockMarkers(clockRegistrationId: number): Observable<ClockMarker[]> {
    return this.http.get<ClockMarker[]>(this.apiUrl + 'api/ClockElaborations/GetClockMarkers?clockRegistrationId=' + clockRegistrationId);
  }

  getClockRegistrationElaborationDetail(userRegistrationId: number): Observable<ClockRegistrationElaborationDetail> {
    return this.http.get<ClockRegistrationElaborationDetail>(this.apiUrl + 'api/ClockRegistrationElaborations/GetDetail?userRegistrationId=' + userRegistrationId);
  }

  putClockRegistrationElaborationDetail(detail: ClockRegistrationElaborationDetail): Observable<unknown> {
    let notes = detail.notes;
    if (notes == null) notes = ''
    return this.http.put(this.apiUrl + 'api/ClockRegistrationElaborations/PutNotes?registrationID=' + detail.userRegistrationID + '&notes=' + notes, detail);
  }
  putClockRegistrationElaborationDetailApprove(detail: ClockRegistrationElaborationDetail): Observable<unknown> {
    let notes = detail.notes;
    if (notes == null) notes = ''
    return this.http.put(this.apiUrl + 'api/ClockRegistrationElaborations/PutNotesAndApprove?registrationID=' + detail.userRegistrationID + '&notes=' + notes, detail);
  }
}

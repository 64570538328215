<div class="content-block dx-card">
  <dx-data-grid [dataSource]="workersDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="registrationId"
                (onInitialized)="saveWorkersGridInstance($event)"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="workerDescription"
                dataType="string"
                caption="Dipendente"
                [allowEditing]="false">
    </dxi-column>
    @if(registrationDetail.mezziAssociatiAiDipendenti > 0)
    {
    <dxi-column dataField="toolDescription"
                dataType="string"
                caption="Mezzo"
                [allowEditing]="false">
    </dxi-column>
    }
    <dxi-column dataField="workedHours"
                caption="Ore"
                [width]="100"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="clockHours"
                caption="Timbratura"
                [width]="100"
                [allowEditing]="false"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="errorLevel"
                caption="E"
                [allowEditing]="false"
                [allowFiltering]="false"
                [width]="60"
                cellTemplate="errorCellTemplate">
      <dxo-lookup [dataSource]="lookupErrorData"
                  valueExpr="id"
                  displayExpr="name">
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="approved"
                dataType="boolean"
                caption="A"
                [width]="80">
    </dxi-column>
    <dxo-master-detail [enabled]="true"
                       template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <app-clock-validation-detail [key]="masterDetailItem.data.clockRegistrationId"
                                   [rowData]="masterDetailItem.data">
      </app-clock-validation-detail>
    </div>
    <div *dxTemplate="let d of 'errorCellTemplate'"
         [class]="d.value == 2 ? 'warn' : (d.value == 3 ? 'err' : 'ok')">
    </div>
  </dx-data-grid>
</div>

@if(registrationDetail.mezzi > 0){

<div class="content-block dx-card">
  <dx-data-grid [dataSource]="toolsDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="registrationId"
                (onInitialized)="saveToolsGridInstance($event)"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="toolCode"
                dataType="string"
                caption="Cod."
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="toolDescription"
                dataType="string"
                caption="Mezzo"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="workedHours"
                caption="Ore"
                [width]="100"
                [allowFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="approved"
                dataType="boolean"
                caption="A"
                [width]="80">
    </dxi-column>
  </dx-data-grid>
</div>

}

@if(registrationDetail.allegati > 0){

<div class="content-block dx-card">
  <dx-data-grid [dataSource]="attachmentsDataSource"
                [showBorders]="true"
                [showColumnHeaders]="true"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="attachmentId"
                (onInitialized)="saveAttachmentGridInstance($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="false"
                 [allowAdding]="false"
                 [allowDeleting]="true">
    </dxo-editing>
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxi-column dataField="fileName"
                dataType="string"
                caption="Allegati"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="delete"></dxi-button>
      <dxi-button text="Apri"
                  icon="to"
                  hint="Apri"
                  [onClick]="onOpenClick">
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

}
<div class="content-block dx-card responsive-paddings">
  <dx-form id="form"
           labelLocation="left"
           labelMode="floating"
           [formData]="registrationDetail"
           [showValidationSummary]="true"
           validationGroup="registrationData"
           [colCount]="2">
    <dxo-col-count-by-screen [xs]="2"></dxo-col-count-by-screen>
    <dxi-item dataField="constructionSiteCode"
              editorType="dxLookup"
              [editorOptions]="cantiereEditorOptions"
              [isRequired]="true"
              [colSpan]="faseVisible ? 1 : 2">
      <dxo-label text="Nuovo Cantiere"></dxo-label>
    </dxi-item>
    <dxi-item dataField="workingPhaseCode"
              editorType="dxLookup"
              [editorOptions]="faseEditorOptions"
              [colSpan]="1"
              [visible]="faseVisible">
      <dxo-label text="Nuova Fase"></dxo-label>
    </dxi-item>
    <dxi-item dataField="notes"
              editorType="dxTextArea"
              [editorOptions]="notesEditorOptions"
              [colSpan]="2">
      <dxo-label text="Note"></dxo-label>
    </dxi-item>
  </dx-form>
  <div class="buttonContainer">
    <div class="innerButton">
      <dx-button stylingMode="contained"
                 text="Salva Modifiche"
                 type="success"
                 [width]="280"
                 icon="save"
                 (onClick)="saveClick()">
      </dx-button>
    </div>
    <div class="innerButton">
      <dx-button stylingMode="contained"
                 text="Salva Modifiche ed Approva"
                 type="success"
                 [width]="280"
                 icon="check"
                 (onClick)="saveApproveClick()">
      </dx-button>
    </div>
  </div>
</div>


<div class="buttonContainer">
  <div class="innerButton">
    <dx-button stylingMode="outlined"
               [width]="25"
               icon="arrowleft"
               (onClick)="sinistraClick()">
    </dx-button>
  </div>
  <div class="dateRange">
    <dx-date-range-box startDateLabel="Inizio"
                       endDateLabel="Fine"
                       labelMode="floating"
                       [min]="minDate"
                       [max]="maxDate"
                       [width]="330"
                       [startDate]="startDate"
                       [endDate]="endDate"
                       (onValueChanged)="onValueChanged($event)"></dx-date-range-box>
  </div>
  <div class="innerButton">
    <dx-button stylingMode="outlined"
               [width]="30"
               icon="arrowright"
               (onClick)="destraClick()">
    </dx-button>
  </div>
</div>
<div class="dx-card">
  <dx-data-grid id="gridContainer"
                [allowColumnReordering]="true"
                [columnAutoWidth]="true"
                [dataSource]="remoteDataSource"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="clockRegistrationId"
                (onCellPrepared)="onCellPrepared($event)"
                (onEditorPreparing)="onEditorPreparing($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="batch"
                 [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="false">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxi-column dataField="registrationDate"
                dataType="date"
                sortOrder="desc"
                caption="Data"
                [format]="{ type: 'dd/MM' }"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="dipendente"
                caption="Dipendente"
                [allowEditing]="false">
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxi-column dataField="constructionSiteCode"
                caption="Cantiere"
                [allowEditing]="true"
                >
      <dxo-lookup [dataSource]="constructionSitesStore"
                  valueExpr="constructionSiteCode" 
            displayExpr="constructionSiteDescription"> 
        </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxi-column dataField="fase"
                caption="Fase"
                [allowEditing]="false"
                [visible]="faseVisible">
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxi-column dataField="workedHours"
                caption="Ore"
                [width]="100">
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="errorLevel"
                caption="E"
                [allowEditing]="false"
                [width]="60"
                cellTemplate="errorCellTemplate">
      <dxo-lookup [dataSource]="lookupErrorData"
                  valueExpr="id"
                  displayExpr="name">
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="approved"
                dataType="boolean"                
                caption="A"
                [width]="80"
                >
    </dxi-column>
    <dxo-master-detail [enabled]="true"
                       template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <app-clock-validation-detail [key]="masterDetailItem.key"
                                   [rowData]="masterDetailItem.data">
      </app-clock-validation-detail>
    </div>
    <div *dxTemplate="let d of 'errorCellTemplate'"
         [class]="d.value == 2 ? 'warn' : (d.value == 3 ? 'err' : 'ok')">
    </div>

  </dx-data-grid>
</div>


import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Registration } from 'src/app/shared/models/registration';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { HoursOption } from 'src/app/shared/models/hours-option';
import { Subject, takeUntil } from 'rxjs';
import { alert } from 'devextreme/ui/dialog';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';
import { Tool } from '../../shared/models/tool';

@Component({
  selector: 'app-registration-workers-tool',
  templateUrl: './registration-workers-tool.component.html',
  styleUrls: ['./registration-workers-tool.component.scss']
})
export class RegistrationWorkersToolComponent {
  registration!: Registration | undefined;
  option: HoursOption | any;
  tools: Tool[] = [];
  dataSource: any;

  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private router: Router) {
    this.registration = service.getNewRegistration();
  }

  ngOnInit() {
    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Back());
    this.toolbar.forwardClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Forward());

    this.service.getToolsObservable().pipe(takeUntil(this.unsubscribe)).subscribe((tls) => this.tools = tls);

    this.service.getHoursOptionObservable().pipe(takeUntil(this.unsubscribe)).subscribe(val => {
      this.option = val;
    });

    this.toolbar.BackVisibleChanged(true);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(true);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    this.service.getTempRegistration(Number(regId)).then((reg) => {
      this.registration = reg;
      if (this.registration) {
        this.registration.workerRegistrations
        
        this.dataSource = {
          store: this.registration.workerRegistrations,
          key: 'workerNumber'
        };
        
      }
    });


  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField == "toolCode") {
      e.editorName = "dxLookup";  // "dxSelectBox" or "dxDropDownBox"  
      e.editorOptions.searchEnabled = true;
      e.editorOptions.searchExpr = "toolDescription"
      e.editorOptions.searchMode = "contains"
      e.editorOptions.showClearButton = true;
      e.editorOptions.usePopover = false;
      e.editorOptions.wrapItemText = true;
      e.editorOptions.placeholder = "Selezionare il Mezzo"
      e.editorOptions.dropDownOptions = {
        fullScreen: true,
        showTitle: false
      }
    }
  }

  Forward() {
    this.grid.instance.saveEditData().then(() => this.saveRegistration());
  }

  private saveRegistration() {
    if (this.registration) {
      // verifico che vi sia almeno una riga inserita
      if (this.option.toolRegistrationsEnabled) {
        this.service.editTempRegistration(this.registration, this.registration.id ?? 0)
          .then((regId) => {

            this.router.navigate(['/registration-tools-select', { id: regId }])
          });
      }
      else {

        this.registration.id ??= 0;

        if (this.registration.id > 0) {
          this.service.editRegistration(this.registration, this.registration.id ?? 0)
            .then(() => {

              this.router.navigate(['/registrations'])
            });
        }
        else {
          this.service.addRegistration(this.registration)
            .then(() => {

              this.router.navigate(['/registrations'])
            });

        }
      }
    }
    else {
      alert("Nessuna registrazione individuata", "Errore");
    }
  }

  Back() {
    if (this.registration) {
      this.service.editTempRegistration(this.registration, this.registration.id ?? 0).then((regId) => this.router.navigate(['/registration-worker-hour', { id: regId }]))
    }
    else {
      alert("Nessuna registrazione individuata", "Errore");
    }
  }
}

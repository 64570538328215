import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { RowDblClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Subject, takeUntil } from 'rxjs';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-clocks-admin',
  templateUrl: './clocks-admin.component.html',
  styleUrls: ['./clocks-admin.component.scss']
})
export class ClocksAdminComponent {

  readonly allowedPageSizes = [10, 50, 200];

  private unsubscribe: Subject<void> = new Subject<void>();
  private selectedRowIndex = -1;
  private selectedRowKey = -1;
  remoteDataSource: DataSource;

  @ViewChild(DxDataGridComponent, { static: false }) grid!: DxDataGridComponent;

  constructor(
    private toolbar: ToolbarService,
    private restApi: RestApiService,
    private router: Router) {

    this.remoteDataSource = restApi.getClockAdminDataSorce();
  }
  ngOnInit() {
    this.toolbar.TitleChanged("Gestione Timbrature");

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(true);
    this.toolbar.EditVisibleChanged(true);
    this.toolbar.DeleteVisibleChanged(true);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);
    this.toolbar.ResourceMenuVisibleChanged(false);

    this.toolbar.plusClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Plus());
    this.toolbar.editClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Edit());
    this.toolbar.deleteClickedEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.Delete());

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectedChanged(e: SelectionChangedEvent) {
    this.selectedRowKey = e.selectedRowKeys[0];
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  onRowDblClick(e: RowDblClickEvent) {
    this.selectedRowIndex = e.rowIndex;
    this.selectedRowKey = e.data.clockId;

    this.router.navigate(['/clock-admin', { id: this.selectedRowKey }]);
  }

  Plus() {
    this.router.navigate(['/clock-admin']);
  }

  Edit() {
    this.router.navigate(['/clock-admin', { id: this.selectedRowKey }]);
  }

  Delete() {
    this.grid.instance.deleteRow(this.selectedRowIndex);
    this.grid.instance.deselectAll();
  }
}

import { Component, ViewChild } from '@angular/core';
import { Registration } from 'src/app/shared/models/registration';
import { HoursOption } from 'src/app/shared/models/hours-option';
import { Worker } from 'src/app/shared/models/worker';

import { alert, confirm } from 'devextreme/ui/dialog';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { DxFileUploaderComponent, DxFormComponent } from 'devextreme-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Constructionsite } from 'src/app/shared/models/constructionsite';
import { ConstructionPhase } from 'src/app/shared/models/construction-phase';

import ArrayStore from 'devextreme/data/array_store';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { LoggingService } from '../../shared/services/logging.service';
import { UploadedEvent, UploadErrorEvent } from 'devextreme/ui/file_uploader';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent {
  @ViewChild(DxFormComponent, { static: false }) form! : DxFormComponent;
  @ViewChild('fileUploader') fileUploaderRef!: DxFileUploaderComponent;

  registration:  Registration | undefined;
  registrationId: number | null;
  
  option: HoursOption | undefined;
  currentWorker: Worker | undefined;

  forwardSubscription: any;
  backSubscription: any;

  // è true se derivo da un ritorno da un registrazione in modalità singola (non capocantiere)
  oreLavorateVisible = false;

  constructionSiteDataSubscription: any;
  constructionPhaseDataSubscription: any;
  costDataSubscription: any;

  notesEditorOptions: unknown;
  inizioFineEditorOptions: unknown;
  maxDate: string;

  constructionSitesStore: ArrayStore;
  constructionPhasesStore: ArrayStore;
  costsStore: ArrayStore;

  dataRegistrazioneEditorOptions: unknown;
  cantiereEditorOptions: unknown;
  faseEditorOptions: unknown;
  costoEditorOptions: unknown;

  files: any[] = [];
  guidDirectory = "";

  constructor(
      private service: WorkerAndToolService,
      private toolbar: ToolbarService,
    private route: ActivatedRoute,
    private logger: LoggingService,
      private router: Router) {
    this.notesEditorOptions = { height: 90 };
    this.registrationId = null;

    this.registration = service.getNewRegistration();
    this.maxDate = this.registration.dataRegistrazione.toISOString().split('T')[0];

    this.constructionSitesStore = new ArrayStore(
      {
        key: "constructionSiteCode",
      }
    )

    this.dataRegistrazioneEditorOptions = {
      dateSerializationFormat: "yyyy-MM-dd"

    };

    this.inizioFineEditorOptions = {
      type: "time",
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
    };

    this.cantiereEditorOptions = {
      dataSource: this.constructionSitesStore,
      searchEnabled: true,
      displayExpr: "constructionSiteDescription",
      valueExpr: "constructionSiteCode",
      showClearButton: true,
      searchExpr: "constructionSiteDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il cantiere",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.constructionPhasesStore = new ArrayStore(
      {
        key: "constructionPhaseCode",
      }
    )

    this.faseEditorOptions = {
      dataSource: this.constructionPhasesStore,
      searchEnabled: true,
      displayExpr: "constructionPhaseDescription",
      valueExpr: "constructionPhaseCode",
      showClearButton: true,
      searchExpr: "constructionPhaseDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare la fase",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.costsStore = new ArrayStore({
      key: "costCode",
      
    });


    this.costoEditorOptions = {
      dataSource: this.costsStore,
      searchEnabled: true,
      displayExpr: "costDescription",
      valueExpr: "costCode",
      showClearButton: true,
      searchExpr: "costDescription",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il costo",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };
  }

  ngOnInit() {
    this.service.getHoursOption().then(val =>
    {
      this.option = val;
      
      this.impostaSalvaAvanti();
      this.impostaOreVisibile();
    });

    this.backSubscription = this.toolbar.backClickedEvent.subscribe(()=>this.Back());
    this.forwardSubscription = this.toolbar.forwardClickedEvent.subscribe(() => this.Forward());

    this.service.getCurrentWorker().then(val =>
    {
      this.currentWorker = val;
      this.impostaSalvaAvanti();
      this.impostaOreVisibile();
    });
    
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(true);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(false);

    const regId = this.route.snapshot.paramMap.get('id');
    if (regId  != null) this.registrationId = +regId;

    this.oreLavorateVisible = false;
    const back = this.route.snapshot.paramMap.get('back');
    if (back && back === "1") this.oreLavorateVisible = true;

    if (this.registrationId == null)
    {
      this.toolbar.TitleChanged("Nuova");

      Promise.all([this.service.getConstructionSites(), this.service.getCosts()])
        .then(
          (values) => {
            values[0].forEach(f => this.constructionSitesStore.insert(f));
            values[1].forEach(f => this.costsStore.insert(f));
          })
    }
    else
    {
      if (this.registrationId > 0) {
        this.toolbar.TitleChanged("Modifica");
      }
      else {
        if (back) {
          this.toolbar.TitleChanged("Nuova");
        }
        else {
          this.toolbar.TitleChanged("Duplica");
        }
        
      }

      Promise.all([this.service.getConstructionSites(), this.service.getCosts()])
        .then(
          (values) =>
          {
            values[0].forEach( f=> this.constructionSitesStore.insert(f));
            values[1].forEach(f => this.costsStore.insert(f));

            if (this.registrationId) {
              this.service.getTempRegistration(this.registrationId).then((reg) => this.registration = reg);
            }
          })
    }
  }

  impostaSalvaAvanti() {
    if (this.option && this.currentWorker && !this.option.toolRegistrationsEnabled && !this.currentWorker.siteManagerMode) {
      this.toolbar.SaveVisibleChanged(true);
      this.toolbar.ForwardVisibleChanged(false);
    }
    else {
      this.toolbar.SaveVisibleChanged(false);
      this.toolbar.ForwardVisibleChanged(true);
    }
  }

  impostaOreVisibile() {
    if (this.option && this.currentWorker && !this.currentWorker.siteManagerMode) {
      this.oreLavorateVisible = true;
    }
  }

  ngOnDestroy() {
    this.backSubscription.unsubscribe();
    this.forwardSubscription.unsubscribe();
  }

  onFieldDataChanged(e: FieldDataChangedEvent) {
    if (e.dataField === "cantiere") {

      this.constructionPhasesStore.clear();
      this.service.getConstructionPhases(e.value).then(
        phases => {
          phases.forEach(f => this.constructionPhasesStore.insert(f));
          if (this.registration && this.registration.fase) {
            if (!phases.find(f => this.registration && f.constructionPhaseCode == this.registration.fase)) {
              this.registration.fase = null;
            }            
          }

          this.form.instance.getEditor("fase")?.repaint();
      });
    }
  }

  onUploadError(e: UploadErrorEvent): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Collegamento Fallito';
    }
    //this.retryButtonVisible = true;
  }

  onUploaded(e: UploadedEvent): void {

    if (this.registration) {
      if (!this.registration.attachments) {
        this.registration.attachments = [];
      }

      this.registration.attachments.push(e.file.name);
    }
    
  }

  onFileUploaderValueChanged(e: any): void {
    //creo un guid per creare la cartella in cui archiviare
    if (this.registration) {
      if (this.registration.filesDir == null) {
        this.guidDirectory = "";
      }
      else {
        this.guidDirectory = this.registration.filesDir;
      }
    }

    if (this.guidDirectory == "") {
      this.guidDirectory = this.service.getUniqueId(4);
    }

    this.fileUploaderRef.uploadUrl = environment.ApiUrl + '/api/FileUpload/PostFiles?uuid=' + this.guidDirectory + '/';
    if (this.registration) {
      this.registration.filesDir = this.guidDirectory;
    }
  }

  Back()
  {
    const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Abbandono");
        result.then((dialogResult) => {
            if (dialogResult)
            {
                // Torno all'indice
                this.router.navigate(['/registrations']);
            }
        });
  }

  Forward()
  {
    const result = this.form.instance.validate();

    if (result.isValid && this.registration != null && this.currentWorker) {
      // Qui devo gestire il caso del worker singolo
      if (this.currentWorker.siteManagerMode) {
        // SE ho un id faccio un update, altrimenti un insert
        if (this.registrationId == null) {
          this.service.addTempRegistration(this.registration).then((regId) => this.router.navigate(['/registration-worker-select', { id: regId }]))
        }
        else {
          this.service.editTempRegistration(this.registration, this.registrationId).then((regId) => this.router.navigate(['/registration-worker-select', { id: regId }]))
        }
      }
      else {
        let oreLavorate = this.registration.oreLavorate;
        oreLavorate ??= 8;

        this.registration.workerRegistrations.splice(0);
        this.registration.workerRegistrations.push(
          {
            workerNumber: this.currentWorker.workerNumber,
            workerDescription: this.currentWorker.surname + ' ' + this.currentWorker.name,
            oreLavorate: oreLavorate,
            toolCode: null
          });


        if (this.option?.toolRegistrationsEnabled) {
          if (this.registrationId == null) {
            this.service.addTempRegistration(this.registration).then((regId) => this.router.navigate(['/registration-tools-select', { id: regId }]))
          }
          else {
            this.service.editTempRegistration(this.registration, this.registrationId).then((regId) => this.router.navigate(['/registration-tools-select', { id: regId }]))
          }
        }
        else {
          if (this.registrationId == null || this.registrationId < 0) {
            this.service.addRegistration(this.registration).then(() => this.router.navigate(['/registrations']))
          }
          else {
            this.service.editRegistration(this.registration, this.registrationId).then(() => this.router.navigate(['/registrations']))
          }
        }

      }
    }
    else if (this.registration == null) {
      // Se il valore è nullo o indefinito invio un messaggio
      alert("Nessuna registrazione individuata", "Errore");
    }
    else if (this.currentWorker == null) {
      // Se il valore è nullo o indefinito invio un messaggio
      const user = localStorage.getItem("user");

      alert("Nessun lavoratore con il codice '" + user + "'", "Errore");
      this.service.getWorkers().then((workers) => workers.forEach(w => this.logger.logError("Nessun lavoratore con il codice '" + user + "'", JSON.stringify(w))));
    }

  }
}

<div id="clockPanel" class="content-block dx-card responsive-paddings">
  <dx-form id="form"
           labelLocation="left"
           labelMode="floating"
           [formData]="clock"
           (onFieldDataChanged)="onFieldDataChanged($event)"
           [showValidationSummary]="true"
           validationGroup="registrationData">
    <dxo-col-count-by-screen [xs]="2"></dxo-col-count-by-screen>
    <dxi-item dataField="utcDeviceDate"
              editorType="dxDateBox"
              [isRequired]="true"
              [editorOptions]="dataRegistrazioneEditorOptions"
              [colSpan]="2">
      <dxo-label text="Data e ora"> </dxo-label>
      <dxi-validation-rule type="required"
                           message="La data è richiesta">
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="constructionSiteCode"
              editorType="dxLookup"
              [editorOptions]="cantiereEditorOptions"
              [isRequired]="true"
              [colSpan]="2">
      <dxo-label text="Cantiere"></dxo-label>
      <dxi-validation-rule type="required"
                           message="Il cantiere è richiesto">
      </dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="workingPhaseCode"
              editorType="dxLookup"
              [editorOptions]="faseEditorOptions"
              [isRequired]="(option?.workingPhase ?? 0 ) == 2"
              [colSpan]="2"
              [visible]="(option?.workingPhase ?? 0 ) >= 1">
      <dxo-label text="Fase"></dxo-label>
    </dxi-item>
    <dxi-item dataField="notes"
              editorType="dxTextArea"
              [caption]
              [editorOptions]="notesEditorOptions"
              [visible]="option?.notesEnabled"
              [colSpan]="2">
      <dxo-label text="Note"></dxo-label>
    </dxi-item>
  </dx-form>
  <div class="buttonContainer">
    <div class="innerButton">
      <dx-button stylingMode="contained"
                 text="Entrata"
                 type="success"
                 [disabled]="buttonsDisabled"
                 [visible]="entrataVisible"
                 [width]="120"
                 icon="arrowright"
                 (onClick)="entrataClick()">
      </dx-button>
    </div>
    <div class="innerButton">
      <dx-button stylingMode="contained"
                 text="Uscita"
                 type="danger"
                 [disabled]="buttonsDisabled"
                 [visible]="uscitaVisible"
                 [width]="120"
                 icon="arrowleft"
                 (onClick)="uscitaClick()">
      </dx-button>
    </div>
  </div>
</div>
<dx-load-panel #loadPanel
               shadingColor="rgba(0,0,0,0.4)"
               [position]="{ of: '#clockPanel' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true"
               [showPane]="true"
               [shading]="true"
               [hideOnOutsideClick]="false">
</dx-load-panel>

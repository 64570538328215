<dx-data-grid #grid
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [dataSource]="remoteDataSource"
              [repaintChangesOnly]="true"
              (onSelectionChanged)="selectedChanged($event)"
              (onRowDblClick)="onRowDblClick($event)"
              keyField="clockId">
  <dxo-selection mode="single"></dxo-selection>
  <dxo-paging [pageSize]="20"> </dxo-paging>
  <dxo-pager [visible]="true"
             [showInfo]="true"
             [showPageSizeSelector]="true"
             [showNavigationButtons]="true"
             [allowedPageSizes]="allowedPageSizes">
    </dxo-pager>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-editing [allowUpdating]="false"
                 [allowAdding]="false"
                 [allowDeleting]="false">
    </dxo-editing>
    <dxi-column dataField="clockId"
                caption="Id">
    </dxi-column>
    <dxi-column dataField="deviceDate"
                dataType="date"
                sortOrder="desc"
                caption="Data"
                [format]="{ type: 'dd/MM/yyyy HH:mm' }">
    </dxi-column>

    <dxi-column dataField="direction"
                caption="Dir">
    </dxi-column>
    <dxi-column dataField="nomeDipendente"
                caption="Nome">
    </dxi-column>
    <dxi-column dataField="cognomeDipendente"
                caption="Cognome">
    </dxi-column>
    <dxi-column dataField="descrizioneCantiere"
                caption="Cantiere">
    </dxi-column>
    <dxi-column dataField="descrizioneFase"
                caption="Fase">
    </dxi-column>
    <dxi-column dataField="distanzaCantiereInMetri"
                caption="Distanza">
      <dxo-format type="fixedPoint"
                  [precision]="0">
      </dxo-format>
    </dxi-column>
    <dxi-column dataField="notes"
                dataType="string"
                cellTemplate="noteTemplate"
                caption="Note">
    </dxi-column>
    <dxi-column dataField="manual"
                dataType="boolean"
                caption="Manuale">
    </dxi-column>
    <div *dxTemplate="let data of 'noteTemplate'">
      <div class="multiline-note">{{ data.value }}</div>
    </div>
</dx-data-grid>





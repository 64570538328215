import { Component } from '@angular/core';
import { CustomStore, createStore } from 'devextreme-aspnet-data-nojquery';
import { CellPreparedEvent, EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/date_range_box';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-clock-registration-validation',
  templateUrl: './clock-registration-validation.component.html',
  styleUrls: ['./clock-registration-validation.component.scss']
})
export class ClockRegistrationValidationComponent {

  now: Date;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;

  backendURL: string = environment.ApiUrl + '/api';
  remoteDataSource: CustomStore;

  faseVisible = true;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private restApi: RestApiService,
    private toolbar: ToolbarService) {

    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
    this.startDate = this.setToMonday(this.now);
    this.endDate = this.addDays(this.startDate, 7);
    this.minDate = this.addDays(this.startDate, - 120);
    this.maxDate = this.endDate;

    this.remoteDataSource = this.createDataSource();
  }

  onValueChanged(e: ValueChangedEvent) {
    this.startDate = e.value[0];
    this.endDate = e.value[1];

    this.remoteDataSource = this.createDataSource();
  }

  createDataSource(): CustomStore {
    return createStore({
      key: 'userRegistrationID',
      loadUrl: this.backendURL + '/ClockElaborations/GetRegistrations?TenantId=' + this.restApi.readTenantId() + '&StartDate=' + this.startDate.toISOString() + '&EndDate=' + this.endDate.toISOString(),
    });
  }

  ngOnInit() {
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(false);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(null);

    this.toolbar.TitleChanged("Validazione Registrazioni e Timbrature");

    this.restApi.getOptions().pipe(takeUntil(this.unsubscribe)).subscribe(o => this.faseVisible = (o?.workingPhase ?? 0) >= 1);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setToMonday(date: Date): Date {
    const monday = new Date(date.getTime());

    const day = monday.getDay() || 7;
    if (day !== 1)
      monday.setHours(-24 * (day - 1));
    return monday;
  }
  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  sinistraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, -7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };

  destraClick = () => {
    this.startDate = this.setToMonday(this.addDays(this.startDate, 7));
    this.endDate = this.addDays(this.startDate, 7);
    this.remoteDataSource = this.createDataSource();
  };
}

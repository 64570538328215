import { Component } from '@angular/core';
import { WorkerAndToolService } from 'src/app/shared/services/worker-and-tool.service';
import { ToolbarService } from 'src/app/shared/services/toolbar.service';
import { Router } from '@angular/router';
import { DxDataGridModule, DxSelectBoxModule, DxCheckBoxModule } from 'devextreme-angular';
import { Registration } from 'src/app/shared/models/registration';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { AuthResponseDto, AuthService } from '../../shared/services';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss']
})

export class RegistrationsComponent {

  plusSubscription: any;

  registrations!: Registration[];

  mezziVisible = true;

  public get registrationColSpan() {
    if (this.mezziVisible) return 3;
    return 2;
  }

  public get finalrowColSpan() {
    return this.registrationColSpan + 2
  }

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    private authService: AuthService,
    private router: Router) {

  }

  ngOnInit() {
    this.service.getRegistrations().then((reg)=>this.registrations = reg);

    this.plusSubscription = this.toolbar.plusClickedEvent.subscribe(()=>this.Plus());

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(true);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(true);

    this.toolbar.TitleChanged("Registrazioni");

    this.service.getConstructionSitesCount().then(c =>
    {
      if (c == 0) {
        this.router.navigate(['/syncdata']);
      }
      else {
        
        this.service.getHoursOption().then(o => {
          let tenantIdStorage = localStorage.getItem("tenantId");
          tenantIdStorage ??= "0";
          const currentTenantId = +tenantIdStorage;

          if (o && o.tenantId != currentTenantId) {
            this.router.navigate(['/syncdata']);
          }
        })
      }
    })

    const loginOptions = this.authService.Auth;

    if (loginOptions.clockActive && !loginOptions.registrationActive) {
      this.router.navigate(['/clocks'])
    }

    this.service.getHoursOption().then(o => {
      if (o === undefined) {
        this.mezziVisible = false;
      }
      else {
        this.mezziVisible = o.toolRegistrationsEnabled;
        if (this.toolbar.syncTablesEnabled != o.syncTablesOnly) {
          this.toolbar.SyncTablesVisibleChanged(o.syncTablesOnly);
        }
      }
    })
  }

  customizeTotaleOre(cellInfo: { value: string; }) {
    if (cellInfo.value == "0") return "";
    return cellInfo.value;
  }

  onCellPrepared(e: CellPreparedEvent) {
    if (e.rowType === "group" && e.column.dataField === "totaleOreManodopera") {
      e.cellElement.innerHTML = e.cellElement.innerHTML.replace("dx-datagrid-summary-item ", "dx-datagrid-summary-item totaleOreManodopera ");
    }
    if (e.rowType === "group" && e.column.dataField === "totaleOreMezzi") {
      e.cellElement.innerHTML = e.cellElement.innerHTML.replace("dx-datagrid-summary-item ", "dx-datagrid-summary-item totaleOreMezzi ");
    }
  }

  onRowPrepared(e: RowPreparedEvent) {
    if (e.rowType === "group" && e.groupIndex === 0 && e.rowElement && e.rowElement.childNodes && e.rowElement.childNodes.length > 0) {

      const tableSectionElement = e.rowElement as HTMLTableSectionElement;

      if (tableSectionElement) {
        e.rowElement.childNodes[0].childNodes.forEach(f => {
          const html = f as HTMLTableCellElement;
          html.classList.add("group-data");
        });
      }
    }
    
  }

  ngOnDestroy() {
    if (this.plusSubscription) this.plusSubscription.unsubscribe()
  }


  Plus()
  {
      this.router.navigate(['/registration']);
  }

  onSelectionChanged(e : {selectedRowKeys: number[]})
  {
    if (e.selectedRowKeys.length > 0)
    {
      this.router.navigate(['/registration-detail',{id: e.selectedRowKeys[0]}]);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DxDataGridModule, DxFormModule, DxToolbarModule, DxButtonModule, DxTextAreaModule, DxSelectBoxModule,
  DxTemplateModule, DxLookupModule, DxProgressBarModule, DxScrollViewModule, DxDateBoxModule,
  DxListModule, DxMapModule, DxSpeedDialActionModule, DxFileUploaderModule, DxDateRangeBoxModule, DxPopupModule, DxGalleryModule,
  DxLoadPanelModule 
  } from 'devextreme-angular';

import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { RegistrationComponent } from './pages/registration/registration.component';
import { RegistrationToolsHourComponent } from './pages/registration-tools-hour/registration-tools-hour.component';
import { RegistrationToolsSelectComponent } from './pages/registration-tools-select/registration-tools-select.component';
import { RegistrationWorkersHourComponent } from './pages/registration-workers-hour/registration-workers-hour.component';
import { RegistrationWorkersSelectComponent } from './pages/registration-workers-select/registration-workers-select.component';
import { RegistrationsComponent } from './pages/registrations/registrations.component';
import { RegistrationDetailComponent } from './pages/registration-detail/registration-detail.component';
import { RegistrationSummaryComponent } from './pages/registration-summary/registration-summary.component';
import { RegistrationsByResourceComponent } from './pages/registrations-by-resource/registrations-by-resource.component';
import { SyncdataComponent } from './pages/syncdata/syncdata.component';
import { MessageComponent } from './pages/message/message.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { SearchRegistrationsComponent } from './pages/search-registrations/search-registrations.component';
import { ClockDetailComponent } from './pages/clock-detail/clock-detail.component';
import { ClocksComponent } from './pages/clocks/clocks.component';
import { ClockComponent } from './pages/clock/clock.component';
import { ClockAdminComponent } from './pages/clock-admin/clock-admin.component';
import { ClocksAdminComponent } from './pages/clocks-admin/clocks-admin.component';
import { ExpensesComponent } from './pages/expenses/expenses.component';
import { RegistrationDriversSelectComponent } from './pages/registration-drivers-select/registration-drivers-select.component';
import { RegistrationDriversKilometersComponent } from './pages/registration-drivers-kilometers/registration-drivers-kilometers.component';
import { ClockValidationComponent } from './pages/clock-validation/clock-validation.component';
import { ClockRegistrationValidationComponent } from './pages/clock-registration-validation/clock-registration-validation.component';
import { ClockValidationDetailComponent } from './shared/components/clock-validation-detail/clock-validation-detail.component';
import { ClockRegistrationValidationDetailComponent } from './shared/components/clock-registration-validation-detail/clock-registration-validation-detail.component';
import { RegistrationWorkersToolComponent } from './pages/registration-workers-tool/registration-workers-tool.component';
import { GridCellDataPipe } from './shared/pipes/grid-cell-data.pipe';

const routes: Routes = [
  {
    path: 'registrations',
    component: RegistrationsComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registrations' }
  },
  {
    path: 'registration-detail',
    component: RegistrationDetailComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registration-detail' }
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registration' }
  },
  {
    path: 'registration-worker-select',
    component: RegistrationWorkersSelectComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registrationWorkersSelect' }
  },
  {
    path: 'registration-worker-hour',
    component: RegistrationWorkersHourComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registrationWorkerHour' }
  },
  {
    path: 'registration-worker-tool',
    component: RegistrationWorkersToolComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registrationWorkerTool' }
  },
  {
    path: 'registration-drivers-select',
    component: RegistrationDriversSelectComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registrationDriversSelect' }
  },
  {
    path: 'registration-drivers-kilometers',
    component: RegistrationDriversKilometersComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registrationDriversKilometer' }
  },
  {
    path: 'registration-tools-select',
    component: RegistrationToolsSelectComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registrationToolsSelect' }
  },
  {
    path: 'registration-tools-hour',
    component: RegistrationToolsHourComponent,
    canActivate: [ AuthGuardService ],
    data: { animation: 'registrationToolsHour' }
  },
  {
    path: 'registrations-by-resource',
    component: RegistrationsByResourceComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registrations-by-resource' }
  },
  {
    path: 'registration-summary',
    component: RegistrationSummaryComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'registration-summary' }
  },
  {
    path: 'search-registrations',
    component: SearchRegistrationsComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'search-registrations' }
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'messages' }
  },
  {
    path: 'message',
    component: MessageComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'message' }
  },
  {
    path: 'syncdata',
    component: SyncdataComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'syncdata' }
  },
  {
    path: 'clock-detail',
    component: ClockDetailComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clock-detail' }
  },
  {
    path: 'clocks',
    component: ClocksComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clocks' }
  },
  {
    path: 'clock',
    component: ClockComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clock' }
  },
  {
    path: 'clock-admin',
    component: ClockAdminComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clock-admin' }
  },
  {
    path: 'clock-validation',
    component: ClockValidationComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clock-validation' }
  },
  {
    path: 'clock-registration-validation',
    component: ClockRegistrationValidationComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clock-registration-validation' }
  },
  {
    path: 'clocks-admin',
    component: ClocksAdminComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'clocks-admin' }
  },
  {
    path: 'expenses',
    component: ExpensesComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'expenses' }
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'registrations'
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    DxFormModule,
    DxToolbarModule,
    DxButtonModule,
    DxTextAreaModule,
    DxDateRangeBoxModule,
    DxSelectBoxModule,
    DxLookupModule,
    DxTemplateModule,
    DxDataGridModule,
    DxScrollViewModule,
    DxProgressBarModule,
    DxListModule,
    DxDateBoxModule,
    DxMapModule,
    DxSpeedDialActionModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxGalleryModule,
    DxLoadPanelModule 
  ],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    GridCellDataPipe,
    RegistrationComponent,
    RegistrationsComponent,
    RegistrationDriversSelectComponent,
    RegistrationDriversKilometersComponent,
    RegistrationToolsSelectComponent,
    RegistrationToolsHourComponent,
    RegistrationWorkersSelectComponent,
    RegistrationWorkersHourComponent,
    RegistrationWorkersToolComponent,
    RegistrationDetailComponent,
    RegistrationSummaryComponent,
    RegistrationsByResourceComponent,
    SyncdataComponent,
    MessageComponent,
    MessagesComponent,
    SearchRegistrationsComponent,
    ClocksComponent,
    ClockDetailComponent,
    ClockComponent,
    ClocksAdminComponent,
    ClockAdminComponent,
    ClockValidationComponent,
    ClockRegistrationValidationComponent,
    ClockRegistrationValidationDetailComponent,
    ExpensesComponent,
    ClockValidationDetailComponent
  ]
})
export class AppRoutingModule { }

<dx-list class="list"
  [dataSource]="listData"
  selectionMode="single"
  pageLoadMode="scrollBottom"
  (onSelectionChanged)="listSelectionChanged($event)">
  <div *dxTemplate="let clock of 'item'">
    <div class="content-block dx-card wide-card responsive-paddings">
      <div>Data e Ora: <b>{{clock.gpsDate | date:'dd/MM/yyyy HH:mm:ss'}}</b></div>
      @if (clock.direction == 'E') {
        <div>Direzione: <b>Entrata</b></div>
      }
      @if (clock.direction == 'U') {
        <div>Direzione: <b>Uscita</b></div>
      }
      <div>Cantiere : <b>{{clock.descrizioneCantiere}}</b></div>
      @if (clock.descrizioneFase) {
        <div>Fase : <b>{{clock.descrizioneFase}}</b></div>
      }
      <div>Precisione : <b>{{clock.precision}}</b> Metri</div>
      <div>Distanza da cantiere : <b>{{clock.distanzaCantiereInMetri | number : '1.0-0'}}</b></div>
    </div>
  </div>
</dx-list>

import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Router } from '@angular/router';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import { SelectionChangedEvent } from 'devextreme/ui/list';
import { WorkerAndToolService } from '../../shared/services/worker-and-tool.service';

@Component({
  selector: 'app-clocks',
  templateUrl: './clocks.component.html',
  styleUrls: ['./clocks.component.scss']
})
export class ClocksComponent {
  listData: any;
  plusSubscription: any;

  constructor(
    private service: WorkerAndToolService,
    private toolbar: ToolbarService,
    restApi: RestApiService,
    private router: Router ) {


    this.listData = restApi.getClockDataSorce();
  }

  ngOnInit() {
    this.toolbar.TitleChanged("Timbrature");

    this.service.getConstructionSitesCount().then(c => {
      if (c == 0) {
        this.router.navigate(['/syncdata']);
      }
      else {

        this.service.getHoursOption().then(o => {
          let tenantIdStorage = localStorage.getItem("tenantId");
          tenantIdStorage ??= "0";
          const currentTenantId = +tenantIdStorage;

          if (o && o.tenantId != currentTenantId) {
            this.router.navigate(['/syncdata']);
          }
        })
      }
    })

    this.toolbar.BackVisibleChanged(false);
    this.toolbar.SaveVisibleChanged(false);
    this.toolbar.ForwardVisibleChanged(false);
    this.toolbar.CancelVisibleChanged(false);
    this.toolbar.PlusVisibleChanged(true);
    this.toolbar.EditVisibleChanged(false);
    this.toolbar.DeleteVisibleChanged(false);
    this.toolbar.CopyVisibleChanged(false);
    this.toolbar.MenuVisibleChanged(true);
    this.toolbar.ResourceMenuVisibleChanged(false);

    this.plusSubscription = this.toolbar.plusClickedEvent.subscribe(() => this.Plus());

  }

  ngOnDestroy() {
    if (this.plusSubscription) this.plusSubscription.unsubscribe()
  }

  listSelectionChanged = (e: SelectionChangedEvent) => {
    if (e.addedItems.length > 0) {
      this.router.navigate(['/clock-detail', { id: e.addedItems[0].clockId }]);
    }
  };

  Plus() {
    this.router.navigate(['/clock']);
  }

}

<div style="width: 40%; float:left">
  @if (errorVisible) {
  <div class="dx-card">
    <div class="textarea-wrapper">
      <dx-text-area [(value)]="errorMessage"
                    [autoResizeEnabled]="true"
                    [inputAttr]="{ 'aria-label': 'Notes' }">
      </dx-text-area>
    </div>
  </div>
  }
  <div class="dx-card">
    <dx-data-grid [dataSource]="clocksDataSource"
                  [showBorders]="true"
                  [showColumnHeaders]="false"
                  [columnAutoWidth]="true">
      <dxo-group-panel [visible]="false"></dxo-group-panel>

      <dxi-column dataField="linkType"
                  [groupIndex]="0"
                  groupCellTemplate="groupCellTemplate">
        <dxo-lookup [dataSource]="lookupData"
                    valueExpr="id"
                    displayExpr="name">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="deviceDate" dataType="datetime" caption="Data"></dxi-column>
      <dxi-column dataField="direction" dataType="string"></dxi-column>
      <dxi-column dataField="distanzaCantiereInMetri" dataType="number">
        <dxo-format type="fixedPoint"
                    [precision]="0">
        </dxo-format>
      </dxi-column>
      <dxi-column type="buttons">
        <dxi-button text="Apri"
                    icon="globe"
                    hint="Apri su Google Maps"
                    [onClick]="apriMaps">
        </dxi-button>
      </dxi-column>
      <div *dxTemplate="let data of 'groupCellTemplate'">
        <div>{{data.displayValue}}</div>
      </div>

    </dx-data-grid>
  </div>
</div>
@if (mapVisible){
<div class="map-holder">
  <dx-map #map
          center="40.7061, -73.9969"
          [zoom]="18"
          [height]="300"
          [autoAdjust]="true"
          width="100%"
          provider="bing"
          [apiKey]="apiKey"
          [controls]="true"
          type="satellite"
          [markers]="markers">
  </dx-map>
  <div class="overlay-button">
    <dx-button icon="search"
               (onClick)="togglePopup()">
    </dx-button>
  </div>
</div>
}
<dx-popup [(visible)]="isPopupVisible"
          [hideOnOutsideClick]="true"
          [resizeEnabled]="true"
          [fullScreen]="true"
          [showTitle]="false"
          contentTemplate="popup-content">
  <div *dxTemplate="let data of 'popup-content'">
    <dx-map id="mapPopup"
            center="40.7061, -73.9969"
            [zoom]="18"
            [autoAdjust]="true"
            height="100%"
            width="100%"
            provider="bing"
            [apiKey]="apiKey"
            [controls]="true"
            type="satellite"
            [markers]="markers">
    </dx-map>
    <div class="overlay-button">
      <dx-button icon="close"
                 (onClick)="togglePopup()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<div class="buttonContainer">
  <div class="innerButton">
    <dx-button stylingMode="outlined"
               [width]="25"
               icon="arrowleft"
               (onClick)="sinistraClick()">
    </dx-button>
  </div>
  <div class="dateRange">
    <dx-date-range-box startDateLabel="Inizio"
                       endDateLabel="Fine"
                       labelMode="floating"
                       [min]="minDate"
                       [max]="maxDate"
                       [width]="330"
                       [startDate]="startDate"
                       [endDate]="endDate"
                       (onValueChanged)="onValueChanged($event)"></dx-date-range-box>
  </div>
  <div class="innerButton">
    <dx-button stylingMode="outlined"
               [width]="30"
               icon="arrowright"
               (onClick)="destraClick()">
    </dx-button>
  </div>
</div>
<div class="dx-card">
  <dx-data-grid id="gridContainer"
                [allowColumnReordering]="true"
                [columnAutoWidth]="true"
                [dataSource]="remoteDataSource"
                [wordWrapEnabled]="true"
                [repaintChangesOnly]="true"
                keyField="clockRegistrationId">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="cell"
                 [allowUpdating]="false"
                 [allowAdding]="false"
                 [allowDeleting]="false">
    </dxo-editing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxi-column dataField="registrationDate"
                dataType="date"
                sortOrder="desc"
                caption="Data"
                [format]="{ type: 'dd/MM' }"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="dipendente"
                caption="Dipendente"
                [allowEditing]="false">
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxi-column dataField="cantiere"
                caption="Cantiere"
                [allowEditing]="false">
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxi-column dataField="fase"
                caption="Fase"
                [allowEditing]="false"
                [visible]="faseVisible">
      <dxo-header-filter [allowSelectAll]="true">
        <dxo-search [enabled]="true"></dxo-search>
      </dxo-header-filter>
    </dxi-column>
    <dxo-master-detail [enabled]="true"
                       template="masterDetail"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <app-clock-registration-validation-detail [key]="masterDetailItem.key"
                                   [rowData]="masterDetailItem.data">
      </app-clock-registration-validation-detail>
    </div>
  </dx-data-grid>
</div>


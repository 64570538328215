<dx-scroll-view height="100%" width="100%" class="with-footer single-card">
  <div class="content-block dx-card responsive-paddings">
    <table class="">
      <tbody>
        <tr><td colspan="3">Data : <b>{{registration.dataRegistrazione  | date:'dd/MM/yyyy' }}</b></td></tr>
        @if (registration.inizio) {
        <tr><td colspan="3">Inizio : <b>{{registration.inizio| slice:11:16}}</b></td></tr>
        }
        @if (registration.fine) {
        <tr><td colspan="3">Fine : <b>{{registration.fine| slice:11:16}}</b></td></tr>
        }
        <tr><td colspan="3">Cantiere : <b>{{registration.descrizioneCantiere}}</b></td></tr>
        @if (registration.fase) {
        <tr><td colspan="3">Fase : <b>{{registration.descrizioneFase}}</b></td></tr>
        }
        @if (registration.costo) {
        <tr><td colspan="3">Costo : <b>{{registration.descrizioneCosto}}</b></td></tr>
        }
        @if (registration.notes) {
        <tr><td colspan="3"><b>Note</b></td></tr>
        }
        @if (registration.notes) {
        <tr><td colspan="3">{{registration.notes}}</td></tr>
        }
        @if (registration.workerRegistrations.length) {
        <tr><td colspan="3"><b>Dipendenti</b></td></tr>
        }
        @for (item of registration.workerRegistrations; track item) {
        <tr>
          <td></td>
          <td>{{item.workerDescription}}</td>
          <td align="right">{{item.oreLavorate}}</td>
        </tr>
        }
        @if (registration.workerKilometers.length) {
        <tr><td colspan="3"><b>Chi guida</b></td></tr>
        }
        @for (item of registration.workerKilometers; track item) {
        <tr>
          <td></td>
          <td>{{item.workerDescription}}</td>
          <td align="right">{{item.km}} Km</td>
        </tr>
        }
        @if (registration.toolRegistrations.length) {
        <tr><td colspan="3"><b>Mezzi</b></td></tr>
        }
        @for (item of registration.toolRegistrations; track item) {
        <tr>
          <td></td>
          <td>{{item.toolDescription}}</td>
          <td align="right">{{item.oreLavorate}}</td>
        </tr>
        }
        @if (registration.attachments?.length) {
        <tr><td colspan="3"><b>Allegati</b></td></tr>
        <tr><td colspan="3">
  <dx-gallery [dataSource]="galleryDataSource"
              [height]="300">
  </dx-gallery>
</tr>
        @for (item of registration.attachments; track item) {
        <tr>
          <td></td>
          <td colspan="2">{{item}}</td>
        </tr>
        }
        }


      </tbody>
    </table>
  </div>
</dx-scroll-view>
